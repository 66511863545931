import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/Seo'

const NotFoundPage = () => (
  <Layout styles='bg-header'>
    <SEO title='Página não encontrada' />
    <div className='container py-5'>
      <div className='row pt-md-5 mt-5'>
        <div className='col-12'>
          <h1>Ops... página não encontrada!</h1>
          <p>Você encontrou uma rota que não existe.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
